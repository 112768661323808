import '../stylesheets/common.scss';

window.addEventListener("load", () => {
  /*===========================================================*/
  /* Hamburger menu */
  /*===========================================================*/
  // Status of menuOpen
  let is_menuOpen = false;

  // Manipulate class name status with the click of a button
  document
    .querySelector(".p-header-custom-sp-nav__btn")
    .addEventListener("click", function () {
      document.querySelector(".p-header-custom-sp-nav").classList.toggle("is-active");
      document
        .querySelector(".p-header-custom-sp-nav__btn")
        .classList.toggle("is-active");
      document.querySelector(".p-header-custom-logo__image-sp-menu").classList.toggle("is-active");
      document.querySelector(".p-header-custom-logo__image").classList.toggle("is-active");

      // Scroll prohibition is removed when the menu is open on click
      if (is_menuOpen) {
        // PC
        document.removeEventListener("wheel", disableScroll, {
          passive: false,
        });
        // SP
        document.removeEventListener("touchmove", disableScroll, {
          passive: false,
        });

        is_menuOpen = false;

        // Scrolling is prohibited when the menu is closed on click
      } else {
        // PC用
        document.addEventListener("wheel", disableScroll, { passive: false });
        // スマホ用
        document.addEventListener("touchmove", disableScroll, {
          passive: false,
        });

        is_menuOpen = true;
      }
    });

  // Hide the SP menu when clicking on links in the menu
  const linkButton = document.querySelectorAll(".p-header-custom-sp-nav li a");
  const linkButtonArray = Array.from(linkButton);

  linkButtonArray.forEach(function (element) {
    element.addEventListener("click", function () {
      document.querySelector(".p-header-custom-sp-nav").classList.toggle("is-active");
      document
        .querySelector(".p-header-custom-sp-nav__btn")
        .classList.toggle("is-active");
    });
  });

  /*===========================================================*/
  /* Function to prohibit scrolling */
  /*===========================================================*/
  const disableScroll = (event) => {
    event.preventDefault();
  };
});


window.addEventListener("load", () => {
  /*===========================================================*/
  /* Touch function invalidation at sp */
  /*===========================================================*/
  let touch =
    "ontouchstart" in document.documentElement ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0;

  if (touch) {
    try {
      for (let si in document.styleSheets) {
        let styleSheet = document.styleSheets[si];
        if (!styleSheet.rules) continue;

        for (let ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
          if (!styleSheet.rules[ri].selectorText) continue;

          if (styleSheet.rules[ri].selectorText.match(":hover")) {
            styleSheet.deleteRule(ri);
          }
        }
      }
    } catch (ex) {}
  }
});


window.addEventListener("DOMContentLoaded", () => {
  /*===========================================================*/
  /* Display page current header menu */
  /*===========================================================*/
  const href = location.href;
  let links = document.querySelectorAll(".p-header-custom-nav > ul > li > a");

  for (let i = 0; i < links.length; i++) {
    console.log(links[i].href);
    if (links[i].href == href) {
      document
        .querySelectorAll(".p-header-custom-nav > ul > li > a")
        [i].classList.add("current");
    }
  }
  if (href.indexOf("consulting") !== -1) {
    document
      .querySelectorAll(".p-header-custom-nav > ul > li > a")[1]
      .classList.add("current");
  }
  if (href.indexOf("learning") !== -1) {
    document
      .querySelectorAll(".p-header-custom-nav > ul > li > a")[1]
      .classList.add("current");
  }
});

window.addEventListener("DOMContentLoaded", () => {
  /*===========================================================*/
  /* Display page current header right menu  */
  /*===========================================================*/
  const href = location.href;
  let links = document.querySelectorAll(
    ".p-header-custom-right-nav > ul > li > a"
  );

  if (href.indexOf("service_tcfd_form") !== -1) {
    document
      .querySelectorAll(".p-header-custom-right-nav > ul > li > a")[0]
      .classList.add("current");
  }
  if (href.indexOf("inquiry") !== -1) {
    document
      .querySelectorAll(".p-header-custom-right-nav > ul > li > a")[1]
      .classList.add("current");
  }
});

window.addEventListener("DOMContentLoaded", () => {
  /*===========================================================*/
  /* Card article title line count display limit */
  /*===========================================================*/
  function textTrim() {
    let elements = document.getElementsByClassName("js-textTrim");

    for (let i = 0; i < elements.length; i++) {
      let style = window.getComputedStyle(elements[i]);
      let fontsize = parseInt(style.fontSize);
      let width = parseInt(elements[i].offsetWidth) - 1;

      let numOfChar = Math.floor(width / fontsize) * 2;

      if (elements[i].innerText.length > numOfChar) {
        let str = elements[i].innerText;
        str = str.substr(0, numOfChar - 1);
        elements[i].innerText = str + "…";
      }
    }
  }
  textTrim();
});
